@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.first-color {
    background-color: #f6d000;
    color: black;
}

.secondary-color {
    background-color: #0E111D;
    color: white;
}

.third-color {
    background-color: #B6BEC9;
    color: black;
}

.fourth-color {
    background-color: #F4F4FA;
    color: black;
}

.fifth-color {
    background-color: white;
    color: black;
}

.window-border-light {
    background-color: white;
    border: solid black 1px;
    color: black;
}

.window {
    padding: 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
}

.window .comments-section {
    margin-top: 50px;
}

.window .comments {
    width: 100%;
    align-content: start;
    display: flex;
    overflow: scroll;
    overflow-y: hidden;
    overflow-x: hidden;
}

.window .window-title {
    font-size: 4rem;
    font-weight: 300;
}

.window .small-title {
    font-size: 1.4rem;
}

.window .description {
    font-size: 1.5rem;
}

.window-title {
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 20px;
}

@media (min-width: 576px) {
    .window-title {
        padding-left: 0;
    }

    .window {
        border-radius: 15px;
    }
}

@media (min-width: 768px) {
    #intro {
        width: 30%;
        height: 70vh;
    }

    .window {
        padding: 40px;
    }
}

.gradient {
    height: 10px;
    background-image: -webkit-gradient(linear, left top, right top, from(#f6d000), color-stop(yellow), color-stop(orange), color-stop(orangered), color-stop(palevioletred), to(#f6d000));
    background-image: linear-gradient(to right, #f6d000, yellow, orange, orangered, palevioletred, #f6d000);
    -webkit-animation: animatedgradient 5s ease alternate infinite;
            animation: animatedgradient 5s ease alternate infinite;
    background-size: 300% 300%;
}

.gradient-border {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border: solid 3px transparent;
    -webkit-transition: border 0.5s;
    transition: border 0.5s;
}

.gradient-border:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 3px;
    bottom: 3px;
    right: 3px;
    left: 3px;
}

.gradient-border:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 6px);
    width: calc(100% + 6px);
    background: white;
    border-radius: 6px;
    z-index: -2;
}

.gradient-border:hover {
    background: -webkit-gradient(linear, left top, right top, from(#f6d000), color-stop(yellow), color-stop(orange), color-stop(orangered), color-stop(palevioletred), to(#f6d000));
    background: linear-gradient(to right, #f6d000, yellow, orange, orangered, palevioletred, #f6d000);
    -webkit-animation: animatedgradient 3s ease alternate infinite;
            animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

@-webkit-keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
html {
  font-size: 14px;
}

.page {
  min-height: calc(100vh - 4.3rem);
  align-items: center;
}

.page-wrapper {
  padding-top: 20px;
  align-items: start;
}

.custom-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  html {
    font-size: 14px;
  }

  .custom-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }

  .custom-container {
    max-width: 720px;
  }

  .page {
    display: flex;
  }

  .page-wrapper {
    padding-top: 0px;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 16px;
  }

  .custom-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 18px;
  }

  .custom-container {
    max-width: 1140px;
  }
}

@media (min-width: 1600px) {
  .custom-container {
    max-width: 1540px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 20px;
  }
}

.App {
  min-height: 100vh;
  font-size: 24px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
header {
    font-family: 'Nunito', sans-serif;
}

header .contact {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header a {
    color: black;
    text-decoration: none !important;
}

header a:hover {
    color: black;
}

.contact .name {
    font-size: 1.5rem;
}

.contact .info {
    display: flex;
    align-items: center;
}

.contact .email {
    display: none;
    padding-right: 15px;
    font-size: 1rem;
}

.contact .wrapper {
    border-radius: 25px;
    border: solid black 3px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.2rem;
}

.contact .wrapper:hover {
    border: solid transparent;
}

.contact .number {
    padding-left: 6px;
}

nav {
    position: relative;
    background-color: #f6d000;
    align-items: center !important;
    display: none !important;
    padding: 0.5rem 0.5rem;
    flex-flow: row nowrap;
}

nav ul {
    flex-direction: row;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: space-between;
}

nav ul li {
    font-size: 1rem;
    font-weight: 600;
    padding: 0 10px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    .contact .email {
        display: block;
        padding-right: 15px;
        font-size: 1rem;
    }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}
.brand-logo {
    width: 100%;
}

#about-us .wrapper {
}

#about-us .title {
    font-size: 1.6rem;
}

#about-us .text {
    display: flex;
    align-items: flex-end;
}

#about-us .logo {
    display: flex;
    align-items: center;
}

#about-us .description {
    font-size: 1rem;
}

#about-us .between {
    justify-content: space-between;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    #about-us .title {
        font-size: 3rem;
    }

    #about-us .description {
        font-size: 1.2rem;
    }
}

@media (min-width: 1200px) {
    .brand-logo {
        padding: 0 40px;
    }
}

@media (min-width: 1600px) {
    #about-us .title {
        font-size: 4rem;
    }

    #about-us .description {
        font-size: 1.4rem;
    }
}

@media (min-width: 1920px) {
}
.what-we-do {
    padding: 40px 0;
}

.offers {
    display: flex;
    padding: 25px 0;
}

.icon {
    color: black;
}

.small-card-container {
    margin: 10px 0;
}

.small-card {
    position: relative;
    padding: 20px 10px;
    border-radius: 15px;
    border: solid white 3px;
    background-color: white;
    text-align: center;
    height: 100%;
    align-items: center;
    display: flex;
}

.small-card:hover {
    border: solid transparent;
}

.small-card .wrapper {
    margin: auto;
}

.small-card svg {
    font-size: 2rem;
    margin-bottom: 15px;
}

.small-card .name {
    font-size: 1rem;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}
.we-will-do {
    width: 100%;
    padding-bottom: 30px;
}

.we-will-do ul {
    list-style-type: none;
    padding: 25px;
    margin: 0;
}

.nav-window {
    background-color: #282c34;
    color: white;
    border-radius: 15px;
}

.we-will-do .card-container {
    margin: 10px 0;
}

.our-job {
    display: flex;
    padding: 25px 0;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}
.two-side-card {
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    cursor: pointer;
}

.two-side-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.two-side-card:hover img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.two-side-card .background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.two-side-card .name {
    position: absolute;
    text-align: center;
    font-size: 2rem;
    font-weight: 100;
    color: white;
    bottom: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
}

.two-side-card .wrapper {
    height: 100%;
    width: 100%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
}

.two-side-card .first {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: white;
    z-index: 1;
}

.two-side-card .secondary {
    overflow: hidden;
    z-index: -1;
    display: flex;
    align-items: center;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    box-sizing: border-box;
    background-color: white;
    padding: 20px;
}

.two-side-card .list {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.two-side-card .flipped {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.two-side-card .element {
    width: 100%;
    border-bottom: solid silver 1px;
    padding: 10px;
    font-size: 1rem;
}

.two-side-card .element:last-of-type {
    border-bottom: none;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
    .two-side-card {
        height: 100%;
    }

    .two-side-card .secondary {
        height: 100%;
    }
}

@media (min-width: 992px) {
    .two-side-card .first {
        min-height: 300px;
    }

    .two-side-card .secondary {
        min-height: 450px;
    }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}
footer .text {
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
    padding: 5px;
}

footer .title {
    font-size: 1.2rem;
    padding-bottom: 10px;
}

footer .phone-number {
    font-size: .9rem;
}

.footer-info {
    padding: 60px 20px;
}

.footer-info .column {
    display: grid;
    align-content: space-between;
}

.work-time .days {
    display: block;
}

.work-time .day {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    font-weight: 300;
}

.address {
    font-size: 0.9rem;
    font-weight: 300;
}

footer a {
    color: white !important;
    text-decoration: none !important;
}

.custom-button {
    font-size: 1rem;
    padding: 5px 10px;
    margin: 20px;
    text-align: center;
    border: solid white 1px;
    border-radius: 25px;
}

.gradient-button {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border: solid 2px transparent;
    background: white;
    -webkit-transition: background-color 0.5s, border .5s;
    transition: background-color 0.5s, border .5s;
    cursor: pointer;
}

.gradient-button:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
}

.gradient-button:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    background: #0E111D;
    border-radius: 4px;
    z-index: -2;
}

.gradient-button:hover {
    background: -webkit-gradient(linear, left top, right top, from(#f6d000), color-stop(yellow), color-stop(orange), color-stop(orangered), color-stop(palevioletred), to(#f6d000));
    background: linear-gradient(to right, #f6d000, yellow, orange, orangered, palevioletred, #f6d000);
    -webkit-animation: animatedgradient 3s ease alternate infinite;
            animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    footer {
        padding-top: 25px;
    }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}
.comments-section {
    padding-bottom: 40px;
}

.comments {
    display: flex;
    padding-top: 25px;
}

.card-container {
    margin: 10px 0;
}

.custom-card {
    border-radius: 10px;
    background-color: white;
    height: 100%;
    padding: 15px;
}

.custom-card .head {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
}

.custom-card .img {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: solid #f6d000 2px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #f6d000;
}

.custom-card img {
    width: 100%;
}

.custom-card .name {
    color: black;
    padding-left: 10px;
    font-size: 1rem;
}

.custom-card .body {
    border-top: #B6BEC9 solid 1px;
    color: black;
    padding: 10px;
}

.custom-card .comment {
    font-size: 1rem;
}

.comments-title {
    font-size: 1rem;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}
