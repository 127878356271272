footer .text {
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
    padding: 5px;
}

footer .title {
    font-size: 1.2rem;
    padding-bottom: 10px;
}

footer .phone-number {
    font-size: .9rem;
}

.footer-info {
    padding: 60px 20px;
}

.footer-info .column {
    display: grid;
    align-content: space-between;
}

.work-time .days {
    display: block;
}

.work-time .day {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    font-weight: 300;
}

.address {
    font-size: 0.9rem;
    font-weight: 300;
}

footer a {
    color: white !important;
    text-decoration: none !important;
}

.custom-button {
    font-size: 1rem;
    padding: 5px 10px;
    margin: 20px;
    text-align: center;
    border: solid white 1px;
    border-radius: 25px;
}

.gradient-button {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border: solid 2px transparent;
    background: white;
    transition: background-color 0.5s, border .5s;
    cursor: pointer;
}

.gradient-button:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
}

.gradient-button:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    background: #0E111D;
    border-radius: 4px;
    z-index: -2;
}

.gradient-button:hover {
    background: linear-gradient(to right, #f6d000, yellow, orange, orangered, palevioletred, #f6d000);
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    footer {
        padding-top: 25px;
    }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}