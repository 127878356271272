.what-we-do {
    padding: 40px 0;
}

.offers {
    display: flex;
    padding: 25px 0;
}

.icon {
    color: black;
}

.small-card-container {
    margin: 10px 0;
}

.small-card {
    position: relative;
    padding: 20px 10px;
    border-radius: 15px;
    border: solid white 3px;
    background-color: white;
    text-align: center;
    height: 100%;
    align-items: center;
    display: flex;
}

.small-card:hover {
    border: solid transparent;
}

.small-card .wrapper {
    margin: auto;
}

.small-card svg {
    font-size: 2rem;
    margin-bottom: 15px;
}

.small-card .name {
    font-size: 1rem;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}