.first-color {
    background-color: #f6d000;
    color: black;
}

.secondary-color {
    background-color: #0E111D;
    color: white;
}

.third-color {
    background-color: #B6BEC9;
    color: black;
}

.fourth-color {
    background-color: #F4F4FA;
    color: black;
}

.fifth-color {
    background-color: white;
    color: black;
}

.window-border-light {
    background-color: white;
    border: solid black 1px;
    color: black;
}

.window {
    padding: 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
}

.window .comments-section {
    margin-top: 50px;
}

.window .comments {
    width: 100%;
    align-content: start;
    display: flex;
    overflow: scroll;
    overflow-y: hidden;
    overflow-x: hidden;
}

.window .window-title {
    font-size: 4rem;
    font-weight: 300;
}

.window .small-title {
    font-size: 1.4rem;
}

.window .description {
    font-size: 1.5rem;
}

.window-title {
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 20px;
}

@media (min-width: 576px) {
    .window-title {
        padding-left: 0;
    }

    .window {
        border-radius: 15px;
    }
}

@media (min-width: 768px) {
    #intro {
        width: 30%;
        height: 70vh;
    }

    .window {
        padding: 40px;
    }
}

.gradient {
    height: 10px;
    background-image: linear-gradient(to right, #f6d000, yellow, orange, orangered, palevioletred, #f6d000);
    animation: animatedgradient 5s ease alternate infinite;
    background-size: 300% 300%;
}

.gradient-border {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border: solid 3px transparent;
    transition: border 0.5s;
}

.gradient-border:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 3px;
    bottom: 3px;
    right: 3px;
    left: 3px;
}

.gradient-border:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 6px);
    width: calc(100% + 6px);
    background: white;
    border-radius: 6px;
    z-index: -2;
}

.gradient-border:hover {
    background: linear-gradient(to right, #f6d000, yellow, orange, orangered, palevioletred, #f6d000);
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}