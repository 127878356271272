.we-will-do {
    width: 100%;
    padding-bottom: 30px;
}

.we-will-do ul {
    list-style-type: none;
    padding: 25px;
    margin: 0;
}

.nav-window {
    background-color: #282c34;
    color: white;
    border-radius: 15px;
}

.we-will-do .card-container {
    margin: 10px 0;
}

.our-job {
    display: flex;
    padding: 25px 0;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}