.brand-logo {
    width: 100%;
}

#about-us .wrapper {
}

#about-us .title {
    font-size: 1.6rem;
}

#about-us .text {
    display: flex;
    align-items: flex-end;
}

#about-us .logo {
    display: flex;
    align-items: center;
}

#about-us .description {
    font-size: 1rem;
}

#about-us .between {
    justify-content: space-between;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    #about-us .title {
        font-size: 3rem;
    }

    #about-us .description {
        font-size: 1.2rem;
    }
}

@media (min-width: 1200px) {
    .brand-logo {
        padding: 0 40px;
    }
}

@media (min-width: 1600px) {
    #about-us .title {
        font-size: 4rem;
    }

    #about-us .description {
        font-size: 1.4rem;
    }
}

@media (min-width: 1920px) {
}