.comments-section {
    padding-bottom: 40px;
}

.comments {
    display: flex;
    padding-top: 25px;
}

.card-container {
    margin: 10px 0;
}

.custom-card {
    border-radius: 10px;
    background-color: white;
    height: 100%;
    padding: 15px;
}

.custom-card .head {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
}

.custom-card .img {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: solid #f6d000 2px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #f6d000;
}

.custom-card img {
    width: 100%;
}

.custom-card .name {
    color: black;
    padding-left: 10px;
    font-size: 1rem;
}

.custom-card .body {
    border-top: #B6BEC9 solid 1px;
    color: black;
    padding: 10px;
}

.custom-card .comment {
    font-size: 1rem;
}

.comments-title {
    font-size: 1rem;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}