html {
  font-size: 14px;
}

.page {
  min-height: calc(100vh - 4.3rem);
  align-items: center;
}

.page-wrapper {
  padding-top: 20px;
  align-items: start;
}

.custom-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  html {
    font-size: 14px;
  }

  .custom-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }

  .custom-container {
    max-width: 720px;
  }

  .page {
    display: flex;
  }

  .page-wrapper {
    padding-top: 0px;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 16px;
  }

  .custom-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 18px;
  }

  .custom-container {
    max-width: 1140px;
  }
}

@media (min-width: 1600px) {
  .custom-container {
    max-width: 1540px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 20px;
  }
}

.App {
  min-height: 100vh;
  font-size: 24px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}