.two-side-card {
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    cursor: pointer;
}

.two-side-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    transition: transform .2s;
}

.two-side-card:hover img {
    transform: scale(1.2);
}

.two-side-card .background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.two-side-card .name {
    position: absolute;
    text-align: center;
    font-size: 2rem;
    font-weight: 100;
    color: white;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
}

.two-side-card .wrapper {
    height: 100%;
    width: 100%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all .5s linear;
}

.two-side-card .first {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: white;
    z-index: 1;
}

.two-side-card .secondary {
    overflow: hidden;
    z-index: -1;
    display: flex;
    align-items: center;
    transform: rotateY(180deg);
    box-sizing: border-box;
    background-color: white;
    padding: 20px;
}

.two-side-card .list {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.two-side-card .flipped {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.two-side-card .element {
    width: 100%;
    border-bottom: solid silver 1px;
    padding: 10px;
    font-size: 1rem;
}

.two-side-card .element:last-of-type {
    border-bottom: none;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
    .two-side-card {
        height: 100%;
    }

    .two-side-card .secondary {
        height: 100%;
    }
}

@media (min-width: 992px) {
    .two-side-card .first {
        min-height: 300px;
    }

    .two-side-card .secondary {
        min-height: 450px;
    }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}