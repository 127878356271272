@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600&display=swap');

header {
    font-family: 'Nunito', sans-serif;
}

header .contact {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header a {
    color: black;
    text-decoration: none !important;
}

header a:hover {
    color: black;
}

.contact .name {
    font-size: 1.5rem;
}

.contact .info {
    display: flex;
    align-items: center;
}

.contact .email {
    display: none;
    padding-right: 15px;
    font-size: 1rem;
}

.contact .wrapper {
    border-radius: 25px;
    border: solid black 3px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.2rem;
}

.contact .wrapper:hover {
    border: solid transparent;
}

.contact .number {
    padding-left: 6px;
}

nav {
    position: relative;
    background-color: #f6d000;
    align-items: center !important;
    display: none !important;
    padding: 0.5rem 0.5rem;
    flex-flow: row nowrap;
}

nav ul {
    flex-direction: row;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: space-between;
}

nav ul li {
    font-size: 1rem;
    font-weight: 600;
    padding: 0 10px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    .contact .email {
        display: block;
        padding-right: 15px;
        font-size: 1rem;
    }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}